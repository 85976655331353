import React from 'react';
import { Helmet } from 'react-helmet';
import ServicesOnHocal from './ServicesOnHocal';
import CreateServiceRequest from './CreateServiceRequest';
import EarnMoney from './EarnMoney';

const AboutUs = () => {
  return (
    <div>


      <div className="md:px-[100px] p-5 my-10">
        <div className=" flex flex-col md:flex-row justify-between items-center w-full">
          <div className="text-section md:w-1/2 md:text-left mb-20 md:mb-0">
            <h1 className="md:text-6xl text-4xl font-bold">Welcome to Hocal <span className='text-white bg-yellow-500 rounded-md px-[10px]'>होआज, होअभी, होकल</span></h1>
            <p className="mt-4 text-base">
              Hocal is a platform where you can easily earn money by assisting nearby individuals with their outdoor or indoor tasks. It's like an open-source platform where you can work on your own terms and generate income effortlessly.
            </p>
            <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
              <a
                href="https://play.google.com/store/apps/details?id=in.hylocal.hocal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-4 px-8 rounded-md shadow-md focus:outline-none inline-block"
              >
                Download App
              </a>


            </div>
          </div>
          <div className="image-section md:w-1/2">
            <img className="rounded-lg w-full" src="image/hocal-about-page.svg" alt="get delivery, services, and earn daily with hocal app" loading='lazy' />
          </div>
        </div>

        <div>
          <h1 className="text-3xl font-bold mt-10 text-black">About Hocal</h1>
          <div>
            <p className="mb-4 text-black">
              Welcome to Hocal (Hylocal Private Limited), your ultimate hyperlocal service app. At Hocal, we are dedicated to building a strong community where everyone helps each other. Our platform connects you with trusted local service providers, making it easy to request reliable help for tasks like car repairs, home service, electronic repair, and more. By leveraging local expertise, we ensure that you receive prompt and efficient service at reduced costs.
            </p>
        
            <h2 className="text-xl lg:text-2xl font-semibold mb-2 text-black">Our Mission</h2>
            <p className="mb-4 text-black">
              Our mission at Hocal is to empower individuals and businesses by creating a seamless platform that fosters a community of mutual support. We aim to enhance your quality of life by providing innovative solutions and exceptional experiences. By enabling local service providers to connect with users, we help our community members earn a living while delivering cost-effective services.
            </p>
            <h2 className="text-xl lg:text-2xl font-semibold mb-2 text-black">Our Vision</h2>
            <p className="mb-4 text-black">
              Our vision is to become the go-to platform for anyone seeking reliable and affordable services. We strive to create a thriving community where service providers and users can connect, collaborate, and support each other. At Hocal, we believe in the power of local networks to drive growth and ensure that everyone benefits.
            </p>
            <h2 className="text-xl lg:text-2xl font-semibold mb-2 text-black">Why Choose Hocal?</h2>
            <p className="mb-4 text-black">
              At Hocal, we prioritize quality, reliability, and community support. With a dedicated team of professionals and a user-friendly interface, we ensure a seamless and enjoyable experience for all our users. Our platform is designed to make your life easier by providing quick access to a wide range of local services. Choose Hocal for trusted, affordable, and efficient solutions that bring our community together.
            </p>
            <h2 className="text-xl lg:text-2xl font-semibold mb-2 text-black">Contact Us</h2>
            <p className="mb-4 text-black">
              Have questions or feedback? We'd love to hear from you! Reach out to us at <a href="mailto:support@hocal.in" className="text-yellow-400">support@hocal.in</a> and let us assist you. Our team is always here to support you and ensure you have the best experience with Hocal.
            </p>
          </div>
        </div>
      </div>

 
      <Helmet>
        <title>About Hocal - Hyperlocal Services Community</title>
        <meta name="description" content="Discover Hocal, a community-driven hyperlocal service app connecting you with trusted local providers. Learn about our mission, vision, why choose Hocal, and how we empower individuals and businesses." />
        <meta name="keywords" content="Hocal, hyperlocal services, community services, local providers, trusted services, affordable services, community-driven platform, mission, vision, why choose Hocal, empower individuals, empower businesses" />
        <meta name="author" content="Hocal Team" />
        <meta property="og:title" content="About Hocal - Hyperlocal Services Community" />
        <meta property="og:description" content="Discover Hocal, a community-driven hyperlocal service app connecting you with trusted local providers. Learn about our mission, vision, why choose Hocal, and how we empower individuals and businesses." />
        <meta property="og:image" content="https://www.hocal.in/image/hocal-about-page.svg" />
        <meta property="og:url" content="https://www.hocal.in/about-us" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Hocal - Hyperlocal Services Community" />
        <meta name="twitter:description" content="Discover Hocal, a community-driven hyperlocal service app connecting you with trusted local providers. Learn about our mission, vision, why choose Hocal, and how we empower individuals and businesses." />
        <meta name="twitter:image" content="https://www.hocal.in/image/hocal-about-page.svg" />
        <link rel="canonical" href="https://www.hocal.in/about-us" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Hocal",
              "url": "https://www.hocal.in",
              "logo": "https://www.hocal.in/image/hocal-about-page.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "email": "support@hocal.in",
                "contactType": "Customer Support"
              },
              "sameAs": [
                "https://www.facebook.com/hocal",
                "https://www.twitter.com/hocal",
                "https://www.linkedin.com/company/hocal"
              ]
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default AboutUs;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Importing Font Awesome icons
import HocalJoinUs from '../Hocal/Components/HocalJoinUs';
import ConnectCommunity from '../Hocal/Components/HocalConnect';
import BusinessWithHocal from '../Hocal/Components/BusinessWithHocal';

const FAQ = () => {
  const initialFaqs = [
    {
      question: 'What is Hocal?',
      answer: 'Hocal is a hyperlocal service app that connects you with trusted service providers, no matter where you are. Whether you are at home or traveling to a new location, Hocal makes it easy to find reliable help for tasks like car repairs, home cleaning, pet sitting, and more. Simply create a service request, and local professionals will come to your aid quickly and efficiently. With Hocal, you always have access to the services you need, right when you need them.',
      isOpen: false
    },
    {
      question: 'What is Hocal Guest?',
      answer: 'As a Hocal Guest, you can effortlessly request any service you need with just a few taps and can save time and money.',
      isOpen: false
    },
    {
      question: 'What is Hocal Chief?',
      answer: 'A Hocal Chief is a key role within the Hocal platform, connecting local service providers with nearby users who need various services. As a Hocal Chief, you use your expertise to assist your community with on-demand help, ranging from technical support and handyman tasks to tutoring and pet care. This role allows you to earn income based on the services you provide, all while making a positive impact in your local area.',
      isOpen: false
    },
    {
      question: 'How to become a Hocal Chief?',
      answer: 'To become a Hocal Chief, download the Hocal app and click "Become Service Provider" under the settings section, Enter your business name, the services you offer, and a brief description. Complete the Aadhaar card verification for security. Once your profile is verified, you can start receiving service requests from local users.',
      isOpen: false
    },
    {
      question: 'Can anyone become a Hocal Chief?',
      answer: 'Yes, anyone can become a Hocal Chief as long as they complete the registration process, provide the necessary business details, and verify their identity with an Aadhaar card. This ensures that all Hocal Chiefs are trustworthy and capable of providing quality services to users in their local area.',
      isOpen: false
    },
    {
      question: 'Can I request for medicine in my local area?',
      answer: 'Yes, you can create a service request for medicines in your local area, you just have to pay for the availability charge and the price of the medicine.',
      isOpen: false
    },
    {
      question: 'Can I request for service other than my location?',
      answer: 'Yes, you can create a service request for any other location using the search bar where you have to search for the location and can create a service request to any chief of that area.',
      isOpen: false
    },
    {
      question: 'If my car gets punctured at any random place, can I get the service?',
      answer: 'Yes, you will definitely get the service from the local chiefs of that area where your car gets punctured, you can simply create a detailed request.',
      isOpen: false
    },
    {
      question: 'Can I create multiple service requests at the same time?',
      answer: 'Yes, you can create multiple service requests at the same time with different chiefs.',
      isOpen: false
    },
  
  ];

  const [faqs, setFaqs] = useState(initialFaqs);

  const toggleAnswer = (index) => {
    const updatedFaqs = faqs.map((faq, i) => {
      if (i === index) {
        return { ...faq, isOpen: !faq.isOpen };
      }
      return faq;
    });
    setFaqs(updatedFaqs);
  };

  return (
    <div className="flex flex-col justify-center rounded-2xl backdrop-blur-md">

      <Helmet>
        <title>Hocal FAQ | Frequently Asked Questions about Our Services</title>
        <meta name="description" content="Find answers to frequently asked questions about Hocal services. Learn more about how Hocal works, the services we offer, and how you can benefit from our platform." />
        <meta name="keywords" content="Hocal frequently asked questions, Hocal FAQ, Hocal services FAQ, what is Hocal, Hocal app, Hocal services app, services on Hocal, Hocal app founder, Hocal platform, Hocal support" />
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What services does Hocal offer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hocal offers a wide range of hyperlocal services, including car repairs, home cleaning, plumbing, electrical repairs, gardening, appliance repairs, furniture assembly, home painting, roof repair, and more."
                }
              },
              {
                "@type": "Question",
                "name": "How can I request a service on Hocal?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "To request a service on Hocal, simply download the Hocal app, sign up, and describe your service needs in detail. Choose from various options and submit your request to get connected with trusted local providers."
                }
              },
              {
                "@type": "Question",
                "name": "Is Hocal chief available in my area?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hocal is continually expanding its services to new areas. Check the app or website to see if Hocal chief is available in your location, if not you can be the first hocal chief for your area."
                }
              },
              {
                "@type": "Question",
                "name": "How do I become a Hocal service provider ( hocal chief )?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "To become a Hocal service provider, sign up on the Hocal platform, create your profile, and showcase your skills and services. Once approved, you can start receiving service requests from users."
                }
              }
            ]
          }
        `}
        </script>
      </Helmet>

      <div className="p-5 my-10 text-black md:px-[100px]">
        <h1 className="text-3xl  font-bold mb-10">Frequently Asked <span className='text-yellow-500'>Questions ?</span></h1>
        {faqs.map((faq, index) => ( 
          <div key={index} className="mb-4">
            <div className="flex items-center justify-between px-4 py-3 cursor-pointer" onClick={() => toggleAnswer(index)}>
              <h2 className="text-sm lg:text-lg ">{faq.question}</h2>
              <div className='ml-2'>
                {faq.isOpen ? <FaMinus className="text-gray-400 text-sm" /> : <FaPlus className="text-gray-400 text-sm" />}
              </div>
            </div>
            {faq.isOpen && <p className="px-4 pb-3 text-sm lg:text-[15px]">{faq.answer}</p>}
          </div>
        ))}
      </div>
    
    </div>
  );
};

export default FAQ;

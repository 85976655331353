import React from 'react'

const HocalJoinUs = () => {
    return (
        <div>


            <div class="md:h-screen md:pt-0 md:px-[100px] p-5 my-5 flex flex-col md:flex-row ">

                <div className="py-5 flex flex-col md:flex-row justify-between  items-center w-full">
                    <div className="text-section md:w-1/2 md:text-left mb-20 md:mb-0">
                        <h1 className="md:text-6xl text-4xl font-bold">Become Hocal Chief and <span className='bg-yellow-500 rounded-lg text-white px-[10px]'>earn money .</span></h1>

                        <p className="mt-4 text-base">
                            Hocal allows anyone to become a service provider and earn easily by offering their skills and services to others nearby.
                        </p>



                        <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
                            {/* Download App Button */}
                            <a
                                href="https://play.google.com/store/apps/details?id=in.hylocal.hocal"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-4 px-8 rounded-md shadow-md focus:outline-none inline-block"
                            >
                                Download App
                            </a>


                        </div>
                    </div>
                    <div className="image-section md:w-1/2 md:px-[60px]">
                        <img className="rounded-lg w-full" src="image/join-as-hocal-chief.svg" alt="Hocal" loading='lazy' />
                    </div>
                </div>



            </div>
        </div>
    )
}

export default HocalJoinUs
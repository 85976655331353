import React from 'react';
import { Helmet } from 'react-helmet';

const ConnectCommunity = () => {
  return (
    <div>


      <div className="md:h-screen md:pt-0 md:px-[100px] p-5 my-5 flex flex-col md:flex-row bg-gradient-to-b from-yellow-50 to-yellow-0">
        <div className=" flex flex-col md:flex-row justify-between items-center w-full">
          <div className="text-section md:w-1/2 md:text-left md:mb-0">
            <h1 className="md:text-6xl text-3xl font-bold">
              Hocal <span className="">connects you with</span> <span className="bg-yellow-500 rounded-sm text-white px-[5px]"> local helpers.</span>
            </h1>
            <p className="mt-4 text-base">
              Hocal is a platform where users can connect with nearby people and help them, earning easily through their assistance. Whether you need help with daily tasks, errands, or specialized services, Hocal brings the community together to make life easier.
            </p>
            <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
              {/* Download App Button */}
              <a
                href="https://play.google.com/store/apps/details?id=in.hylocal.hocal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-4 px-8 rounded-md shadow-md focus:outline-none inline-block"
              >
                Download App
              </a>

            </div>
          </div>
          <div className="image-section md:w-1/2 md:px-[60px] mt-10">
            <img className="rounded-lg w-full" src="image/connect-hocal.svg" alt="Connect with hocal to get nearby services and earn money easily" />
          </div>
        </div>
      </div>

      <Helmet>
        <title>Connect with Local Helpers | Hocal</title>
        <meta name="description" content="Hocal connects you with local helpers for daily tasks, errands, and specialized services. Join the community and make life easier with Hocal." />
        <meta name="keywords" content="Hocal, local helpers, community connection, daily tasks, errands, specialized services, help, assistance" />
        <meta name="author" content="Hocal Team" />
        <meta property="og:title" content="Connect with Local Helpers | Hocal" />
        <meta property="og:description" content="Hocal connects you with local helpers for daily tasks, errands, and specialized services. Join the community and make life easier with Hocal." />
        <meta property="og:image" content="https://www.hocal.in/image/connect-hocal.svg" />
        <meta property="og:url" content="https://hocal.in/connect-community" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Connect with Local Helpers | Hocal" />
        <meta name="twitter:description" content="Hocal connects you with local helpers for daily tasks, errands, and specialized services. Join the community and make life easier with Hocal." />
        <meta name="twitter:image" content="https://www.hocal.in/image/connect-hocal.svg" />
        <link rel="canonical" href="https://hocal.in/connect-community" />
      </Helmet>
    </div>
  );
};

export default ConnectCommunity;
